import SplitType from 'split-type';
import { wrapLines } from './utils';

class TextLinesReveal {
    constructor(animationElems, gsap) {
        this.gsap = gsap;
        this.DOM = {
            animationElems: Array.isArray(animationElems)
                ? animationElems
                : [animationElems],
        };
        // array of SplitType instances
        this.SplitTypeInstances = [];
        // array of all HTML .line
        this.lines = [];

        for (const el of this.DOM.animationElems) {
            const SplitTypeInstance = new SplitType(el, { types: 'lines' });
            // wrap the lines (div with class .oh)
            // the inner child will be the one animating the transform
            wrapLines(SplitTypeInstance.lines, 'div', 'oh');
            this.lines.push(SplitTypeInstance.lines);
            // keep a reference to the SplitType instance
            this.SplitTypeInstances.push(SplitTypeInstance);
        }

        this.initEvents();
    }
    in() {
        // lines are visible
        this.isVisible = true;

        // animation
        this.gsap.killTweensOf(this.lines);
        return this.gsap
            .timeline({ defaults: { duration: 1.5, ease: 'expo' } })
            .set(this.lines, {
                y: '150%',
                rotate: 5,
            })
            .to(this.lines, {
                y: '0%',
                rotate: 0,
                stagger: 0.04,
            });
    }
    out() {
        // lines are invisible
        this.isVisible = false;

        // animation
        this.gsap.killTweensOf(this.lines);
        return this.gsap
            .timeline({
                defaults: { duration: 0.7, ease: 'power2' },
            })
            .to(this.lines, {
                y: '-150%',
                rotate: -3,
                stagger: 0.1,
            });
    }
    initEvents() {
        window.addEventListener('resize', () => {
            // empty the lines array
            this.lines = [];
            // re initialize the Split Text
            for (const instance of this.SplitTypeInstances) {
                // re-split text
                // https://github.com/lukePeavey/SplitType#instancesplitoptions-void
                instance.split();

                // need to wrap again the new lines elements (div with class .oh)
                wrapLines(instance.lines, 'div', 'oh');
                this.lines.push(instance.lines);
            }
            // hide the lines
            if (!this.isVisible) {
                this.gsap.set(this.lines, { y: '-150%' });
            }
        });
    }
}

export const initTexts = (gsap) => {
    return new TextLinesReveal(
        [...document.querySelectorAll('h1, h2, h3, p')],
        gsap,
    );
};
